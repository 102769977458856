import React from "react";
import style from "./style.module.scss";

const Button = ({ innerText, handleClick, disabled, icon, id }) => {
  return (
    <button
      id={id}
      className={!disabled ? style.btnPrimary : style.btnPrimaryDis}
      onClick={handleClick}
      disabled={disabled && disabled}
      style={
        icon && {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }
      }>
      {innerText}
      {icon && <div style={{ margin: "5px 10px 0" }}>{icon}</div>}
    </button>
  );
};

export default Button;
