import React, { useState, useCallback, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useForm } from "react-hook-form";
import { Select } from "antd";
import "./style.scss";

import { getModels } from "../../../../api/requests";
import { checkFilter } from "../../../../Helpers/helpers";
import { setFoundCarsByPage } from "../../../../redux/Reducers/carsReducer";
const { Option } = Select;

const FilterForm = ({ data, setShowHotOffers }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [showBigFilterForm, setShowBigFilterForm] = useState(false);
  const [models, setModels] = useState([]);
  const { handleSubmit, getValues, register, setValue } = useForm();
  const [formData, setFormData] = useState({
    extColor: null,
    intColor: null,
    body: null,
    gearbox: null,
    electricalEngineCount: null,
    steering: null,
    arrivedStatus: null,
    make: null,
    model: null,
    engineType: null,
    year: null,
    priceFrom: null,
    priceTo: null,
    mileage: null,
    kilometers: null,
    horsepower: null,
    volumeFrom: null,
    volumeTo: null,
    wheelDrive: null,
    vin: null,
    customsCleared: null,
    partSelling: null,
    exchange: null,
    hotCar: null,
    soldUrgent: null,
  });

  const searchParams = useSelector(
    (state) => state.router.location.query,
    shallowEqual
  );

  const currentPage = useSelector((state) => state.page);

  const getModel_req = async (selectedMake) => {
    try {
      console.log('selectedMake', selectedMake)
      const result = await getModels({ makeId: selectedMake });
      setModels(result?.data);
      setFormData({ ...formData, make: selectedMake, model: null });
      setValue("make", selectedMake);
    } catch (err) {
      console.error("Error fetching models:", err);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setValue(name, value);
    setFormData({ ...formData, [name]: value });
  };

  const handleDropdownSelect = (value, field) => {
    if (field === "make") {
      getModel_req(value);
    } else {
      setValue(field, value);
      setFormData({ ...formData, [field]: value });
    }
  };

  const onSubmit = (data) => {
    setShowHotOffers(false);
    const finalData = getValues();
    let filteredFinalData = Object.fromEntries(Object.entries(finalData).filter(([_, v]) => v != null));
    try {
      dispatch(setFoundCarsByPage(filteredFinalData, true, () => { }, currentPage, history));
    } catch (err) {
      console.error("Error finding cars:", err);
    }
  };

  const checkAdditionalFilters = checkFilter(data);

  useEffect(() => {
    if (searchParams.make) {
      getModel_req(searchParams.make);
    }

    if (Object.entries(searchParams).length > 0) {
      Object.keys(searchParams).map(key => {
        setValue(key, searchParams?.[key]);
      });
    }
  }, []);

  return (
    <div className="filter-form">
      <h2>Ֆիլտր</h2>
      <form key={4} autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group custom-dropdown">
          <label htmlFor="make">
            {t("Մակնիշ")}
          </label>
          <Select
            showSearch
            popupClassName="dropDown"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={Number(formData?.make || searchParams?.make) || null}
            onSelect={(make) => handleDropdownSelect(make, "make")}
            {...register("make")}
          >
            <Option key={"make"} value={null}>
                &nbsp;
            </Option>
            {data?.make?.map((option) => (
              <Option key={option.id} value={option.id}>
                {option.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="form-group custom-dropdown">
          <label htmlFor="model">
            {t("Մոդել")}
          </label>
          <Select
            showSearch
            popupClassName="dropDown"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={Number(formData?.model || searchParams?.model) || null}
            onSelect={(model) => handleDropdownSelect(model, "model")}
            {...register("model")}
          >
            <Option key={"model"} value={null}>
                &nbsp;
            </Option>
            {models?.map((option) => (
              <Option key={option.id} value={option.id}>
                {option.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="form-group">
          <label>Գին</label>
          <div className="price-range">
            <input
              type="text"
              name="priceFrom"
              value={formData?.priceFrom || searchParams?.priceFrom || null}
              onChange={handleInputChange}
              placeholder="Մինիմում"
              style={{ padding: "16px" }}
              {...register("priceFrom")}
            />
            <input
              type="text"
              name="priceTo"
              value={formData?.priceTo || searchParams?.priceTo || null}
              onChange={handleInputChange}
              placeholder="Մաքսիմում"
              {...register("priceTo")}
            />
          </div>
        </div>
        <div className="form-group custom-dropdown">
          <label htmlFor="bodyType">
            {t("Թափք")}
          </label>
          <Select
            showSearch
            popupClassName="dropDown"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={Number(formData?.bodyType || searchParams?.bodyType) || null}
            onSelect={(bodyType) => handleDropdownSelect(bodyType, "bodyType")}
            {...register("bodyType")}
          >
            <Option key={"bodyType"} value={null}>
                &nbsp;
            </Option>
            {data?.bodyType?.map((option) => (
              <Option key={option.id} value={option.id}>
                {option.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="form-group custom-dropdown">
          <label htmlFor="engine">
            {t("Շարժիչի տեսակ")}
          </label>
          <Select
            showSearch
            popupClassName="dropDown"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={Number(formData?.engine || searchParams?.engine) || null}
            onSelect={(engine) => handleDropdownSelect(engine, "engine")}
            {...register("engine")}
          >
            <Option key={"bodyengineType"} value={null}>
                &nbsp;
            </Option>
            {data?.engine?.map((option) => (
              <Option key={option.id} value={option.id}>
                {option.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="form-group custom-dropdown">
          <label htmlFor="gearbox">
            {t("Փոխանցմանտուփ")}
          </label>
          <Select
            showSearch
            popupClassName="dropDown"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={Number(formData?.gearbox || searchParams?.gearbox) || null}
            onSelect={(gearbox) => handleDropdownSelect(gearbox, "gearbox")}
            {...register("gearbox")}
          >
            <Option key={"gearbox"} value={null}>
                &nbsp;
            </Option>
            {data?.gearbox?.map((option) => (
              <Option key={option.id} value={option.id}>
                {option.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="form-group">
          <label>Ծավալ</label>
          <div className="engine-inputs">
            <input
              type="text"
              name="volumeFrom"
              value={formData.volumeFrom || searchParams?.volumeFrom}
              onChange={handleInputChange}
              placeholder="1800"
              style={{ padding: "16px" }}
              {...register("volumeFrom")}
            />
            <input
              type="text"
              name="volumeTo"
              value={formData.volumeTo || searchParams?.volumeTo}
              onChange={handleInputChange}
              placeholder="1800"
              {...register("volumeTo")}
            />
          </div>
        </div>

        {!showBigFilterForm && checkAdditionalFilters && (
          <button
            type="button"
            className="filterBtn"
            onClick={() => setShowBigFilterForm(true)}>
            {t('advanced_search')}
          </button>
        )}
        {showBigFilterForm && checkAdditionalFilters && (
          <>
            {data?.steering && (
              <div className="form-group custom-dropdown">
                <label htmlFor="steering">
                  {t("Ղեկ")}
                </label>
                <Select
                  showSearch
                  popupClassName="dropDown"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  value={Number(formData?.steering || searchParams?.steering) || null}
                  onSelect={(steering) => handleDropdownSelect(steering, "steering")}
                  {...register("steering")}
                >
                  <Option key={"steering"} value={null}>
                      &nbsp;
                  </Option>
                  {data?.steering?.map((option) => (
                    <Option key={option.id} value={option.id}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
            {data?.wheelDrive && (
              <div className="form-group custom-dropdown">
                <label htmlFor="wheelDrive">
                  {t("Քարշակ")}
                </label>
                <Select
                  showSearch
                  popupClassName="dropDown"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  value={Number(formData?.wheelDrive || searchParams?.wheelDrive) || null}
                  onSelect={(wheelDrive) => handleDropdownSelect(wheelDrive, "wheelDrive")}
                  {...register("wheelDrive")}
                >
                  <Option key={"wheelDrive"} value={null}>
                      &nbsp;
                  </Option>
                  {data?.wheelDrive?.map((option) => (
                    <Option key={option.id} value={option.id}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
            {data?.color && (
              <div className="form-group custom-dropdown">
                <label htmlFor="intColor">
                  {t("Ներքին Գույն")}
                </label>
                <Select
                  showSearch
                  popupClassName="dropDown"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  value={Number(formData?.intColor || searchParams?.intColor) || null}
                  onSelect={(intColor) => handleDropdownSelect(intColor, "intColor")}
                  {...register("intColor")}
                >
                  <Option key={"intColor"} value={null}>
                      &nbsp;
                  </Option>
                  {data?.color?.map((option) => (
                    <Option key={option.id} value={option.id}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
            {data?.exterior && (
              <div className="form-group custom-dropdown">
                <label htmlFor="extColor">
                  {t("Արտաքին գույն")}
                </label>
                <Select
                  showSearch
                  popupClassName="dropDown"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  value={Number(formData?.extColor || searchParams?.extColor) || null}
                  onSelect={(extColor) => handleDropdownSelect(extColor, "extColor")}
                  {...register("extColor")}
                >
                  <Option key={"extColor"} value={null}>
                      &nbsp;
                  </Option>
                  {data?.exterior?.map((option) => (
                    <Option key={option.id} value={option.id}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
            {data?.exchange && (
              <div className="form-group custom-dropdown">
                <label htmlFor="exchange">
                  {t("Փոխանակում")}
                </label>
                <Select
                  showSearch
                  popupClassName="dropDown"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  value={Number(formData?.exchange || searchParams?.exchange) || null}
                  onSelect={(exchange) => handleDropdownSelect(exchange, "exchange")}
                  {...register("exchange")}
                >
                  <Option key={"exchange"} value={null}>
                      &nbsp;
                  </Option>
                  {data?.exchange?.map((option) => (
                    <Option key={option.id} value={option.id}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
            {data?.urgency && (
              <div className="form-group">
                <label htmlFor="urgency">
                  {t("Շտապ")}
                </label>
                <Select
                  showSearch
                  popupClassName="dropDown"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  value={Number(formData?.soldUrgent || searchParams?.soldUrgent) || null}
                  onSelect={(soldUrgent) => handleDropdownSelect(soldUrgent, "soldUrgent")}
                  {...register("soldUrgent")}
                >
                  <Option key={"soldUrgent"} value={null}>
                      &nbsp;
                  </Option>
                  {data?.urgency?.map((option) => (
                    <Option key={option.id} value={option.id}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
            {data?.deliveryStatus && (
              <div className="form-group">
                <label htmlFor="deliveryStatus">
                  {t("Առաքման կարգավիճակը")}
                </label>
                <Select
                  showSearch
                  popupClassName="dropDown"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  value={Number(formData?.arrivedStatus || searchParams?.arrivedStatus) || null}
                  onSelect={(arrivedStatus) => handleDropdownSelect(arrivedStatus, "arrivedStatus")}
                  {...register("arrivedStatus")}
                >
                  <Option key={"arrivedStatus"} value={null}>
                      &nbsp;
                  </Option>
                  {data?.deliveryStatus?.map((option) => (
                    <Option key={option.id} value={option.id}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
            {data?.customsCleared && (
              <div className="form-group">
                <label htmlFor="customsCleared">
                  {t("Մաքսազերծված է")}
                </label>
                <Select
                  showSearch
                  popupClassName="dropDown"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  value={Number(formData?.customsCleared || searchParams?.customsCleared) || null}
                  onSelect={(customsCleared) => handleDropdownSelect(customsCleared, "customsCleared")}
                  {...register("customsCleared")}
                >
                  <Option key={"customsCleared"} value={null}>
                      &nbsp;
                  </Option>
                  {data?.customsCleared?.map((option) => (
                    <Option key={option.id} value={option.id}>
                      {option.name}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
          </>
        )}
        <button type="submit" className="filterBtn">
          {t('submit_btn')}
        </button>
      </form>
    </div>
  );
};

export default FilterForm;
