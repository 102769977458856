import React, { useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import Navbar from './Navbar';

const NavbarWrapper = () => {
  const user = useSelector((state) => state.user, shallowEqual);
  const location = useLocation();
  const path = location.pathname.slice(1, 5);
  const [modalState, setModalState] = useState({ isVisible: false, initialForm: '', message: '' }); //state for login/signup modal
  useMemo(() => {
    if (path === 'sell' && !user.info)
      setModalState((modalState) => {
        return { ...modalState, isVisible: true };
      });
    else
      setModalState((modalState) => {
        return { ...modalState, isVisible: false };
      });
  }, [path, user.info]);

  return <Navbar user={user} path={path} modalState={modalState} setModalState={setModalState} />;
};

export default NavbarWrapper;
