import React, { useEffect, useState } from "react";
import "./style.scss";
import { getCounts } from "../../../api/requests";
import { useTranslation } from "react-i18next";

const HomeAboutUs = () => {
  const { t } = useTranslation();
  const [dataCounts, setDataCounts] = useState({
    dealerCount: 0,
    clients: 0,
    cars: 0,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getCounts_req = async () => {
      try {
        const getCountsData = await getCounts();
        setDataCounts({
          dealerCount: getCountsData.dealerCount || 0,
          clients: getCountsData.clients || 0,
          cars: Math.round(getCountsData.cars) || 0,
        });
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    getCounts_req();
  }, []);

  if (loading) return <p>{t("loading")}</p>;
  if (error)
    return (
      <p>
        {t("error")}: {error}
      </p>
    );

  return (
    <div className="home-about-us" id="about-us">
      <div className="content">
        <h2 className="content-header">{t("about_autobuy")}</h2>
        <h1 className="content-why-us">{t("why_choose_autobuy")}</h1>
        <p className="content-header content-header-p">
          {t("about_autobuy_description")}
        </p>
      </div>
      <div className="stats">
        <div className="stats-container">
          <div className="stat-item">
            <p className="stat-item-p">{dataCounts.clients} +</p>
            <span className="stat-item-p stat-item-span">
              {t("satisfied_clients")}
            </span>
          </div>
          <div className="stat-item">
            <p className="stat-item-p">{dataCounts.cars} +</p>
            <span className="stat-item-p stat-item-span">
              {t("transported_cars")}
            </span>
          </div>
          {/* <div className="stat-item">
            <p className="stat-item-p">{dataCounts.dealerCount}+</p>
            <span className="stat-item-p stat-item-span">{t("dealers")}</span>
          </div> */}
        </div>
        <div className="home-about-us-img" />
      </div>
    </div>
  );
};

export default HomeAboutUs;
