import React, { useEffect } from "react";
import InnerContainer from "./InnerContainer";
import style from "./style.module.scss";
import disableScroll from "disable-scroll";
import MenuOutlined from '@ant-design/icons/MenuOutlined';

const MenuWrapper = ({
  path,
  user,
  modalState,
  setModalState,
  visible,
  setVisible,
}) => {
  const insideMobileMenu = true;

  useEffect(() => {
    visible ? disableScroll.on() : disableScroll.off();
  }, [visible]);

  return (
    <React.Fragment>
      <div
        className={
          visible ? `${style.closeIcon} ${style.menuIcon}` : style.menuIcon
        }>
        <MenuOutlined onClick={() => setVisible(!visible)} />
      </div>
      <div
        className={
          visible
            ? `${style.menuMobile} ${style.backgroundblack}`
            : `${style.menuMobile} ${style.hidden} `
        }>
        <InnerContainer
          path={path}
          user={user}
          modalState={modalState}
          setModalState={setModalState}
          insideMobileMenu={insideMobileMenu}
          setVisible={setVisible}
        />
      </div>
    </React.Fragment>
  );
};

export default MenuWrapper;
