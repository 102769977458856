import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import CarCard from "../../../../components/Common/Cards/CarCard";
const BASE_URL = process.env.REACT_APP_BASE_URL || "http://localhost:4000";

const RelatedCars = () => {
  const relatedCars = useSelector(
    (state) => state.carDetails.relatedCars,
    shallowEqual
  );
  
  return (
    <React.Fragment>
      {relatedCars.length > 0 &&
        relatedCars.map((car) => {
          const firstMedia =
            !car.carMedia || !car.carMedia.length
              ? ""
              : BASE_URL +
                car.carMedia[0].carImage.location +
                "/" +
                car.carMedia[0].carImage.name +
                "medium.webp";
          return (
            <CarCard
              key={car.id}
              id={car.id}
              make={car.carMake}
              model={car.carModel}
              year={car.year}
              image={firstMedia}
              price={car.price}
              currency={car.currency}
            />
          );
        })}
    </React.Fragment>
  );
};

export default RelatedCars;
