import React from "react";
import "./style.scss";
import { servicesData } from "../../../fakeData";
import { useTranslation } from "react-i18next";

const HomeService = () => {
  const { t } = useTranslation();
  return (
    <div className="home-service" id="services">
      <h1 className="title">{t("services")}</h1>
      <div className="services-grid">
        {servicesData.map((service) => (
          <div key={service.id} className="service-item">
            <div className="service-image" />
            <div className="service-info">
              <p className="description">{t(service.title)}</p>
              <p className="price">{t(service.price)}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeService;
